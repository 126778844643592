
nav {
    position: fixed;
    top: 0;
    z-index: 100;
}

.navbarButton {
    display: 'flex';
    gap: "8px";
    width: "100%";
}
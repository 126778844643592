  .code {
    position: relative;
    width: 100%;
    height: 100%;
    background: #1D1E22;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    z-index: 5;
    header {
      display: grid;
      grid-template-columns: 36px auto 36px;
      align-items: center;
      width: 100%;
      height: 40px; /* You can adjust the height as needed */
      background: #393a40;
    
      svg {
        margin: 10px;
      }
    
      h1 {
        font-family: Lato, sans-serif;
        font-weight: 900;
        font-size: 14px;
        letter-spacing: 1.57px;
        color: #C5C8D4;
        margin: 0;
      }
    }
    .code-content {
      white-space: pre-wrap;
      color: white;
      .c-r { color: #A88038 }
      .c-o { color: #DE7300 }
      .c-y { color: #E1CA72 }
      .c-g { color: #74B087 }
      .c-p { color: #9F8198 }
      .c-b { color: #7A99AD }
      .c-c { color: #666666 }
    }
  }

  
  
  @keyframes typing-1 {
    from { width: 0 }
    to { width: 60px }
  }
  @keyframes typing-2 {
    from { width: 0 }
    to { width: 220px }
  }
  @keyframes typing-3 {
    from { width: 0 }
    to { width: 70px }
  }
  @keyframes typing-4 {
    from { width: 0 }
    to { width: 75px }
  }
  @keyframes typing-5 {
    from { width: 0 }
    to { width: 141px }
  }
  
  
  @keyframes cursor {
    from, to, 20%, 80% { border-color: transparent }
    25%, 75% { border-color: white }
  }
  
  @keyframes cursor-persist {
    from, 45% { border-color: transparent }
    50%, to { border-color: white }
  }